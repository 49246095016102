import { Grid, makeStyles } from '@material-ui/core';
import { useRef, useEffect } from 'react';
import content from '../../../content/careers/intro.json';
import { defaultSectionWidth, emphasizedSizeText, smallSubtitle } from '../../../styles/dimensions';
import MetroSans from '../../../fonts/metro-sans/metroSans';
import colors from '../../../styles/colors';
import { StaticImage } from 'gatsby-plugin-image';
import { splitData } from '../../../helpers/splitData';
import AnimatedImage from '../../../elements/animatedImage';
// @ts-ignore
import image1 from '../../../images/careers/careers_photo_1.jpg';
// @ts-ignore
import image2 from '../../../images/careers/careers_photo_2.jpg';

const Intro = () => {
	const classes = useClasses();

	const headerColumnRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		splitData(0.65, 0.02);

		setTimeout(() => {
			headerColumnRef.current.style.opacity = '1';
		}, 1);
	}, []);

	return (
		<section className={classes.section}>
			<Grid container className={classes.grid}>
				<Grid item sm={1}></Grid>
				<Grid item xs={12} sm={10} md={6} className={classes.headerColumn} ref={headerColumnRef}>
					<h1 className={classes.header} data-scroll data-scroll-speed="2" data-splitting>
						{content.header.title}
					</h1>
					<p className={classes.headerContent} data-scroll data-scroll-speed="2" data-splitting>
						{content.header.description}
					</p>
				</Grid>
				<Grid item sm={1} md={5}></Grid>

				<Grid item xs={12} sm={6} className={classes.photo1Column} data-scroll data-scroll-speed="2">
					<AnimatedImage image={image1} className={classes.animatedPhoto1} />

					<StaticImage
						src="../../../images/careers/careers_photo_1.jpg"
						alt=""
						layout="constrained"
						loading="eager"
						placeholder="blurred"
						className={classes.photo1}
						aria-hidden="true"
					/>
				</Grid>
				<Grid item sm={6} data-scroll data-scroll-speed="2">
					<AnimatedImage image={image2} className={classes.animatedPhoto2} />

					<StaticImage
						src="../../../images/careers/careers_photo_2.jpg"
						alt=""
						layout="constrained"
						loading="eager"
						placeholder="blurred"
						className={classes.photo2}
						aria-hidden="true"
					/>
				</Grid>
			</Grid>
		</section>
	);
};

export default Intro;

const useClasses = makeStyles({
	section: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		paddingTop: 'calc(7.625em + 2px)',
		'@media (max-width: 599px)': {
			paddingTop: 'calc(2.625em + 2px)',
		},
		'@media (min-width: 600px) and (min-height: 1200px)': {
			paddingTop: 'calc(7.625em + 2px + 3vh)',
		},
	},
	header: {
		fontSize: emphasizedSizeText.fontSize,
		lineHeight: emphasizedSizeText.lineHeight,
		marginTop: '1.2777777777777777em',
		marginBottom: '0.2222222222222222em',
		'@media (max-width: 599px)': {
			fontSize: smallSubtitle.fontSize,
			lineHeight: smallSubtitle.lineHeight,
			marginTop: '-0.85em',
			position: 'relative',
			zIndex: 1,
			paddingLeft: '0.5em',
			paddingRight: '0.5em',
		},
		'& span': {
			fontFamily: 'inherit',
			fontSize: 'inherit',
			lineHeight: 'inherit',
		},
	},
	headerContent: {
		fontFamily: MetroSans.book,
		margin: '0.8em 0',
		color: colors.textGrey,
		'@media (max-width: 599px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
		},
		'& span': {
			fontFamily: 'inherit',
			fontSize: 'inherit',
			lineHeight: 'inherit',
		},
	},
	photo1: {
		maxWidth: 'unset',
		width: 'calc(100% + 8.33333333% * 2)',
		position: 'relative',
		'@media (min-width: 600px)': {
			transform: 'translateY(20%)',
		},
		'@media (min-width: 1200px)': {
			display: 'none !important',
		},
	},
	animatedPhoto1: {
		maxWidth: 'unset',
		width: 'calc(100% + 8.33333333% * 2)',
		position: 'relative',
		transform: 'translateY(20%)',
		'@media (max-width: 1199px)': {
			display: 'none !important',
		},
		'@media (min-width: 1200px)': {
			height: '435px',
		},
	},
	photo2: {
		'@media (max-width: 599px)': {
			display: 'none !important',
		},
		'@media (min-width: 1200px)': {
			display: 'none !important',
		},
	},
	animatedPhoto2: {
		'@media (max-width: 1199px)': {
			display: 'none !important',
		},
		'@media (min-width: 1200px)': {
			height: '616px',
		},
	},
	grid: {
		marginBottom: '5.75em',
		'@media (max-width: 599px)': {
			marginBottom: '2.5em',
		},
	},
	photo1Column: {
		'@media (max-width: 599px)': {
			order: 1,
		},
		'@media (min-width: 1200px)': {
			minHeight: '620px',
		},
	},
	headerColumn: {
		opacity: 0,
		'@media (max-width: 599px)': {
			order: 2,
		},
	},
});
