import { Grid, makeStyles } from '@material-ui/core';
import content from '../../../content/careers/assessmentStages.json';
import MetroSans from '../../../fonts/metro-sans/metroSans';
import colors from '../../../styles/colors';
import { defaultSectionWidth, regularText, smallText } from '../../../styles/dimensions';

const AssessmentStages = () => {
	const classes = useClasses();

	return (
		<section className={classes.section}>
			<Grid container>
				<Grid item sm={1}></Grid>

				<Grid item xs={12} sm={10}>
					<h2 className={classes.header} data-scroll data-scroll-speed="3">
						{content.title}
					</h2>

					<div>
						{content.stages.map((stage, key) => (
							<div key={key} data-scroll data-scroll-speed="3">
								<h6 className={classes.subheader}>{stage.title}</h6>
								<p className={classes.content}>{stage.description}</p>
							</div>
						))}
					</div>
				</Grid>

				<Grid item sm={1}></Grid>
			</Grid>
		</section>
	);
};

export default AssessmentStages;

const useClasses = makeStyles({
	section: {
		maxWidth: defaultSectionWidth,
		margin: 'calc(6.25em - 2.75em) auto',
		marginBottom: '11.5em',
		'@media (max-width: 599px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
			marginTop: '1em',
		},
	},
	header: {
		marginTop: 0,
		marginBottom: '1em',
		'@media (max-width: 599px)': {
			fontSize: regularText.fontSize,
			lineHeight: regularText.lineHeight,
			marginBottom: '1.4em',
		},
	},
	subheader: {
		marginTop: 0,
		marginBottom: '0.5em',
	},
	content: {
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		color: colors.textGrey,
		marginTop: '0.5em',

		'@media (min-width: 960px)': {
			maxWidth: 'calc(50% * 12 / 10)',
		},
	},
});
