import { Grid, makeStyles } from '@material-ui/core';
import { Link } from 'gatsby';
import content from '../../../content/careers/openPositions.json';
import MetroSans from '../../../fonts/metro-sans/metroSans';
import Rigraf from '../../../fonts/rigraf/regular/rigraf';
import colors from '../../../styles/colors';
import { defaultSectionWidth, smallText } from '../../../styles/dimensions';
import { StaticImage } from 'gatsby-plugin-image';

const OpenPositions = () => {
	const classes = useClasses();

	return (
		<section className={classes.section} id="open-positions">
			<Grid container>
				<Grid item sm={1}></Grid>
				<Grid item xs={12} sm={6}>
					<h2 className={classes.header} data-scroll data-scroll-speed="2">
						{content.header.title}
					</h2>
					<p className={classes.headerContent} data-scroll data-scroll-speed="2">
						{content.header.description}
					</p>
				</Grid>
				<Grid item sm={5}></Grid>

				<Grid item sm={1}></Grid>
				<Grid item xs={12} sm={10}>
					<div>
						{content.positions.length ? (
							content.positions.map((position, key) => (
								<div key={key} data-scroll data-scroll-speed="1">
									<h4 className={classes.subheader}>{position.title}</h4>
									<span className={classes.location}>{position.location}</span>
									<p className={classes.positionDescription}>{position.description}</p>

									<Link to={position.link} className={classes.link}>
										<StaticImage
											src="../../../images/careers/arrow_icon.png"
											alt="Read more and apply for job position"
											className={classes.arrowIcon}
											layout="constrained"
											loading="lazy"
											placeholder="blurred"
											aria-hidden="true"
										/>
										<span className={classes.linkSpan}>{content.readMore.description}</span>
									</Link>

									<hr className={classes.divider} />
								</div>
							))
						) : (
							<p className={classes.positionDescription} data-scroll data-scroll-speed="2">
								At the moment, we don't have any open positions. But we are always glad to read your CV
								and check out your Git repository. If you would like to send an open application, please
								do! You can do that on our e-mail{' '}
								<a href="mailto:jobs@collectivemind.dev">jobs@collectivemind.dev</a>. Can't wait to hear
								from you &#128515;
							</p>
						)}
					</div>
				</Grid>

				<Grid item sm={1}></Grid>
			</Grid>
		</section>
	);
};

export default OpenPositions;

const useClasses = makeStyles({
	section: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		'@media (max-width: 599px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
		},
	},
	header: {
		margin: '0.4em 0',
	},
	headerContent: {
		fontFamily: MetroSans.book,
		color: colors.textGrey,
		marginTop: '0.8em',
		marginBottom: '3em',
	},
	positionDescription: {
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		marginTop: '1.25em',
		marginBottom: '2.25em',
		color: colors.textGrey,
		maxWidth: 'calc(50% * 12 / 10)',
		'@media (max-width: 599px)': {
			maxWidth: 'unset',
		},
	},
	divider: {
		color: colors.headerBlack,
		marginTop: '3em',
		marginBottom: '2.75em',
	},
	subheader: {
		margin: 0,
		display: 'inline',
	},
	link: {
		display: 'inline-flex',
		flexDirection: 'row',
		alignItems: 'center',
		'&:hover $linkSpan': {
			textDecoration: 'underline',
			textUnderlineOffset: '2px',
		},
	},
	linkSpan: {
		fontFamily: Rigraf.semiBold,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		color: colors.textBlack,
		marginLeft: '1.25em',
		position: 'relative',
		top: '0.125em',
	},
	location: {
		float: 'right',
		'@media (max-width: 599px)': {
			display: 'none',
		},
	},
	arrowIcon: {
		width: '1.5em',
	},
});
