import { Grid, makeStyles } from '@material-ui/core';
import { Fragment } from 'react';
import content from '../../../content/careers/benefits.json';
import colors from '../../../styles/colors';
import { defaultSectionWidth, smallText } from '../../../styles/dimensions';
import { StaticImage } from 'gatsby-plugin-image';
// @ts-ignore
import backgroundGradient from '../../../images/careers/background_benefit.jpg';
// @ts-ignore
import benefitSVG from '../../../images/careers/benefit_atmosphere_icon.svg';
// @ts-ignore
import educationSVG from '../../../images/careers/benefit_education_icon.svg';
// @ts-ignore
import flexibleSVG from '../../../images/careers/benefit_flexible_icon.svg';
// @ts-ignore
import teambuildingSVG from '../../../images/careers/benefit_team_building_icon.svg';
// @ts-ignore
import globalSVG from '../../../images/careers/benefit_global_icon.svg';
// @ts-ignore
import progressSVG from '../../../images/careers/benefit_progress_icon.svg';
// @ts-ignore
import paysSVG from '../../../images/careers/benefit_pays_icon.svg';
// @ts-ignore
import bonusesSVG from '../../../images/careers/benefit_bonuses_icon.svg';

const benefitIcons = [
	benefitSVG,
	educationSVG,
	flexibleSVG,
	teambuildingSVG,
	globalSVG,
	progressSVG,
	paysSVG,
	bonusesSVG,
];

const Benefits = () => {
	const classes = useClasses();

	return (
		<section className={classes.section}>
			<Grid container className={classes.grid} spacing={4}>
				<Grid item xs={12} className={classes.headerColumn}>
					<h2 className={classes.header} data-scroll data-scroll-speed="2">
						{content.title}
					</h2>
				</Grid>

				{content.benefits.map((column, key) => (
					<Fragment key={key}>
						<Grid item xs={6} md={2} className={classes.benefitsColumn}>
							{column.map((benefit, i) => (
								<div
									key={i}
									className={`${classes.benefit} benefit-${key + 1}-${i + 1}`}
									data-scroll
									data-scroll-speed="2">
									<img
										src={benefitIcons[key * column.length + i]}
										alt={benefit.icon.alt}
										className={classes.benefitIcon}
										loading="lazy"
										aria-hidden="true"
									/>
									<p className={classes.benefitText}>{benefit.description}</p>
								</div>
							))}
						</Grid>
						<Grid item md={1}></Grid>
					</Fragment>
				))}

				<Grid item xs={12} md={6} className={classes.imageColumn}>
					<div data-scroll data-scroll-speed="4">
						<StaticImage
							src="../../../images/careers/careers_photo_3.jpg"
							alt=""
							className={classes.image}
							layout="constrained"
							loading="lazy"
							placeholder="blurred"
							aria-hidden="true"
						/>
					</div>
				</Grid>
			</Grid>
		</section>
	);
};

export default Benefits;

const useClasses = makeStyles({
	section: {
		backgroundColor: colors.white,
		backgroundImage: `url(${backgroundGradient})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		padding: '5.25em 0',
		'@media (min-width: 600px) and (max-width: 959px)': {
			paddingTop: 0,
		},
		'@media (max-width: 599px)': {
			paddingBottom: '2.75em',
		},
	},
	grid: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		'& .benefit-1-1': {
			'@media (min-width: 535px) and (max-width: 591px)': {
				minHeight: 'calc(3em + 24px + 0.75em + 4px)',
			},
		},
		'& .benefit-2-1': {
			'@media (min-width: 331px) and (max-width: 361px)': {
				minHeight: 'calc(4.5em + 24px + 0.75em + 4px)',
			},
			'@media (min-width: 960px) and (max-width: 1051px)': {
				minHeight: 'calc(4.5em + 24px + 0.75em + 4px)',
			},
		},
		'& .benefit-1-2': {
			'@media (max-width: 434px)': {
				minHeight: 'calc(3em + 24px + 0.75em + 4px)',
			},
			'@media (min-width: 1037px)': {
				minHeight: 'calc(3em + 24px + 0.75em + 4px)',
			},
		},
		'& .benefit-2-3': {
			'@media (max-width: 342px)': {
				minHeight: 'calc(7.5em + 24px + 0.75em + 4px)',
			},
			'@media (min-width: 343px) and (max-width: 379px)': {
				minHeight: 'calc(6em + 24px + 0.75em + 4px)',
			},
			'@media (min-width: 380px) and (max-width: 524px)': {
				minHeight: 'calc(4.5em + 24px + 0.75em + 4px)',
			},
			'@media (min-width: 724px) and (max-width: 959px)': {
				minHeight: 'calc(3em + 24px + 0.75em + 4px)',
			},
			'@media (min-width: 960px) and (max-width: 994px)': {
				minHeight: 'calc(7.5em + 24px + 0.75em + 4px)',
			},
			'@media (min-width: 995px) and (max-width: 1107px)': {
				minHeight: 'calc(6em + 24px + 0.75em + 4px)',
			},
		},
	},
	benefitIcon: {
		height: '24px',
		width: '24px',
	},
	header: {
		color: colors.textBlack,
		marginTop: 0,
		marginBottom: '0.5em',
		'@media (max-width: 599px)': {
			marginBottom: '0.2em',
		},
	},
	benefitText: {
		color: colors.textGrey,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		margin: '0.75em 0',
	},
	image: {
		maxWidth: 'unset',
		width: 'calc(100% + (100vw - 100%) / 4)',
		objectFit: 'contain',
		maxHeight: '1200px',

		'@media (max-width: 959px)': {
			width: 'unset',
			maxWidth: '60%',
			float: 'right',
		},
		'@media (max-width: 599px)': {
			maxWidth: '80%',
			marginBottom: '1.5em',
			marginTop: '-16em',
		},
	},
	benefit: {
		marginBottom: '1.5em',
	},
	headerColumn: {
		'@media (max-width: 959px)': {
			order: 2,
		},
		'@media (min-width: 600px) and (max-width: 959px)': {
			marginTop: '-6em',
		},
	},
	benefitsColumn: {
		'@media (max-width: 959px)': {
			order: 3,
			maxWidth: 'calc(50% - 16px)',
			flexBasis: 'calc(50% - 16px)',
		},
		'@media (min-width: 600px) and (max-width: 959px)': {
			marginTop: '-1em',
		},
	},
	imageColumn: {
		'@media (max-width: 959px)': {
			order: 1,
		},
		'@media (min-width: 960px)': {
			marginTop: '-22em',
		},
	},
});
