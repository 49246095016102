import Layout from '../components/layout';
import AssessmentStages from '../components/sections/careers/assessmentStages';
import Benefits from '../components/sections/careers/benefits';
import Intro from '../components/sections/careers/intro';
import OpenPositions from '../components/sections/careers/openPositions';
import SEO from '../components/seo';
import { descriptions, titles } from '../consts/seo';

const Careers = () => (
	<Layout>
		<SEO title={titles.careers.main} description={descriptions.careers.main} />
		<Intro />
		<OpenPositions />
		<AssessmentStages />
		<Benefits />
	</Layout>
);

export default Careers;
